import { useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import React from "react";
//import DoneIcon from '@mui/icons-material/DoneOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import {blue} from "@mui/material/colors/";
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@material-ui/core';


function App() {
  const {registrationId} = useParams();
  //const {signature} = useParams();
  //console.log(useParams());
  const [res,setResponse] = useState(null);
  //const [isLoading,setIsLoading] = useState(false);
  const CurrentDate = () => {
    const now = new Date();
    return now.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  const currentDate = CurrentDate();

  //  const color=green[500];

useEffect(() => {
//setIsLoading(true);
const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/pendingpaymentsuccess";
//  console.log(installmentid); // ▶ URLSearchParams {}
  //let data;
  let url = window.location.href;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'plain/text');


  let urlObj = new URL(url);

// Use the URLSearchParams interface to get the signature parameter
  let signature = encodeURIComponent(urlObj.searchParams.get('signature'));

//console.log(signature); // Outputs: $2a$10$uoa7lvrcYIHiXUgE00xBhOI6i3hjvIp6zQaRM6zzELi3OSnPgWYVa

let params={registrationId:registrationId,signature:signature};
let queryString = new URLSearchParams(params).toString();
//console.log(queryString);


  fetch(API_URL+"?"+queryString,{method:'POST'})
    .then(response => response.json())
    .then(data =>
      { setResponse(data);
//        console.log(data);
      })
    .catch(err=>{
//      console.log("ERROR reg success",err);
    })

},[]);

  return (
    <div >
      {res&& <div className="SuccessPage"
      style={{ width: "100%", display: "flex", justifyContent: "flex-end" ,  padding: "15px"}}>
        <CheckCircleIcon style={{ color:"green", fontSize:"40px" }}/>
          <Paper>Your payment is not complete, until it has been approved by the OMC staff.<br/>An email was sent to <b>{res.email}</b>.
          <br/>Pease check you spam/junk folder also.<br />Your payment is still pending:<br/>
          <p><b>Registration Id: </b> {res.registrationId}</p>
          <p><b>First Name: </b> {res.firstName}</p>
            <p><b>Last Name: </b>{res.lastName}</p>
            <p><b>Country: </b>{res.country}</p>
            <p><b>Total Fee: </b>{res.totalFee}€</p>
            <p><b>Attendance Type: </b>{res.section}</p>
        {
            <p><b>General Categories: </b>{res.testNames}</p>}
            <p><b>Please save this information for future reference of your registration.</b></p>


            </Paper>

        </div>}
        {!res &&
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                      <CircularProgress color="secondary" />
        <span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>
        </div>
        }
    </div>
  );
}
export default App;
