import React, {useState, useEffect} from "react";
import { FormControlLabel, IconButton,CircularProgress,Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';

//import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate,Link} from 'react-router-dom'

import { blue,red } from '@material-ui/core/colors';
import { DataGrid, GridToolbar,gridClasses, useGridApiRef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
//import ReactExport from "react-data-export";
import {Box} from '@mui/material/';

//import Alert from '@mui/material/Alert';
//import Paper from '@mui/material/Paper';
//import Input from '@mui/material/Input';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';

import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { blueGrey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';

import AuthService from "./services/auth.service";
//import AuthVerify from "./common/auth-verify";
import authHeader from "./services/auth-header";
import EventBus from "./common/EventBus";

import axios from "axios";

import moment from 'moment';
//import { Document, Page } from 'react-pdf';
import GetAppIcon from '@mui/icons-material/GetApp';


//import AutoSizer from "react-virtualized-auto-sizer";

/*function CustomGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}*/
//const color=indigo[200];



const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[50],
    },
    secondary: {
      main: '#f44336',
    },
  },
});

const ODD_OPACITY = 0.2;


const PendingPayments=() => {

  document.title = "Admin Registration Page";

  const [isLoading,setIsLoading] = useState(false);

  const [pendingPayments,setPendingPayments] = useState([]);
  const [confidentialList,setConfidentialList] = useState([{id:0}]);
    const [failedRegistrations,setFailedRegistrations] = useState([{id:0}]);

  const [regtoEdit,setRegtoEdit]=useState();
  //const [regtoDel,setRegtoDel]=useState();

  //const [editId,setEditid]=useState(false);
  //const [delId,setDelid]=useState(false);

  const [open,setOpen]=useState(false);
  const [delOpen,setDelOpen]=useState(false);
  const navigate=useNavigate();

  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentPage, setCurrentPage] = useState("Registrations");

  const [alertopen,setAlertOpen]=useState(false);
  const [alertmessage,setAlertMessage]=useState("");
  const [alertSeverity,setAlertSeverity]=useState('success');

  const [pageSize, setPageSize] = useState(10);


  const [openphoto,setOpenPhoto]=useState(false);
  const [scrollY,setScrollY]=useState("0");
  const [selectedFile,setSelectedFile]=useState(null);

  /*DataGrid to keep selected layout/Column visibility */
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
      });

   const apiRef = useGridApiRef();

  const jurors = [
   { id: 1, name: 'Juror 1' },
   { id: 2, name: 'Juror 2' },
  { id: 3, name: 'Juror 3' },
  { id: 4, name: 'Juror 4' },
  { id: 5, name: 'Juror 5' },
   { id: 6, name: 'Juror 6' },
   // Add more jurors as needed
  ];

  const tests = [
   { id: 1, name: 'Test 1' },
   { id: 2, name: 'Test 2' },
   // Add more classes as needed
  ];

  const [enrollments, setEnrollments] = useState([
   { jurorId: 1, testId: 1, enrolled: true },
   { jurorId: 1, testId: 2, enrolled: true },
   // Add more enrollments as needed
  ]);

  const handlePreviewEnrollments = () => {
    //console.log('Current enrollments:', enrollments);
    // Additional logic to preview enrollments can be added here
  };

  /*appbar*/
    const [anchorEl, setAnchorEl] = React.useState(null);
    const pages = ['Registrations', 'Failed Registrations'/*,'Juror Assessment'*/];


    const handleChange = (event) => {
    //  setAuth(event.target.checked);
    };



    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    /*appbar*/

    const handleExport  =async () => {
  //    console.log("Clicked");
      setIsLoading(true);
      const myHeaders = authHeader();

      const options = {
        method: 'GET',
        responseType: 'blob',
       headers:myHeaders
      };

      const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/globalexcel";


    //  let res = await fetch(API_URL,options);

    fetch(API_URL,options)
    .then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'GlobalExcel.xlsx';
        a.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(a);
        }, 200);

      });
      //window.location.href = response.url;
    })
    .then(r=>{setIsLoading(false);})
    .catch(err=>{
    EventBus.dispatch("logout");
    alert("Cannot contact server." +err);
    navigate("/admin/Login");
      setIsLoading(false);})
    ;


    };


  const handleSave =async () => {

  const myHeaders = authHeader();


  if (open) { //Save Edit Payment
  setIsLoading(true);
  const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/updateRegistration";
    const options = {
      method: 'POST',
     body:JSON.stringify({
       registrationId:regtoEdit[0].registrationId,
       code:regtoEdit[0].code,organisationName:regtoEdit[0].organisationName,name: regtoEdit[0].name,
       organisationPresident:regtoEdit[0].organisationPresident,
       lastName: regtoEdit[0].lastName,country:regtoEdit[0].country,
       email:regtoEdit[0].email,phone:regtoEdit[0].phone,generalCategory:regtoEdit[0].generalCategory,
       totalFee:regtoEdit[0].totalFee,tests:regtoEdit[0].tests,pendingPayment:!regtoEdit[0].pendingPayment,paid:!regtoEdit[0].paid
     }),
     headers:myHeaders
    };
   let res = await fetch(API_URL,options);
   if (res.ok)
    {
    let responseJson = await res.json()
   .then(async s=>{
     let data;
     const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/registrations";
     const response = axios.get(API_URL1, { headers:myHeaders}).then(response=>{
       return response.data;}).then(data=>
         {
         setPendingPayments(data);setIsLoading(false);setOpen(false);
         setAlertSeverity("success");setAlertMessage("Successful editted registration with id: "+regtoEdit[0].registrationId);
         setAlertOpen(true);
       })
       .catch(err=>{
         setIsLoading(false);setOpen(false);alert("Error while contacting server. "+err);
         setAlertSeverity("error");
         setAlertMessage("Unsuccessful edit!");setAlertOpen(true);
         EventBus.dispatch("logout");navigate("/admin/Login");});
       })
    .catch(err=>{ setAlertSeverity("error");
                setAlertMessage("Unsuccessful edit!");
                setAlertOpen(true);setIsLoading(false);setOpen(false);alert("Error while editting. "+err);
                EventBus.dispatch("logout");
                navigate("/admin/Login");
              })
    }
    else {
      setAlertSeverity("error");
      setAlertMessage("Unsuccessful edit!");
      setAlertOpen(true);setIsLoading(false);setOpen(false);alert("Error while contacting server. "+res);
      EventBus.dispatch("logout");
      navigate("/admin/Login");

    }
   }
   else {//Actions to Refund/ Delete Registration
     setIsLoading(true);
     const COMP_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/deleteRegistration";

     const params={registrationId:regtoEdit[0].registrationId};
     const queryString = new URLSearchParams(params).toString();
     //console.log(queryString);
     const options = {
       method: 'DELETE',
       headers:myHeaders
     };

     let res = await fetch(COMP_API_URL+"?"+queryString,options);
     if (res.ok)
      {
      let responseJson = await res.json().
     then(async d=>
         {//Upload Success
           let data;
           const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/registrations";
           const response = axios.get(API_URL1, { headers:myHeaders})
           .then( response=>{return response.data;})
           .then(data=>
               {
            //   console.log(data);
               setPendingPayments(data);setIsLoading(false);setDelOpen(false);
               setAlertSeverity("success");
               setAlertMessage("Successful delete/refund of registration with id " +regtoEdit[0].registrationId);
               setAlertOpen(true);

             })
             .catch(err=>{        setAlertSeverity("error");
                    setAlertMessage("Unsuccessful delete!");
                    setAlertOpen(true);setIsLoading(false);setDelOpen(false);alert("Error while contacting server. "+err);
                    EventBus.dispatch("logout");
                    navigate("/admin/Login");
                  });
           })
       .catch(err=>{       setAlertSeverity("error");
              setAlertMessage("Unsuccessful delete!");
              setAlertOpen(true);setIsLoading(false);setDelOpen(false);alert("Error while deleting. "+err);
              EventBus.dispatch("logout");
              navigate("/admin/Login");
            })
          }

          else {
            setAlertSeverity("error");
            setAlertMessage("Unsuccessful delete!");
            setAlertOpen(true);setIsLoading(false);setOpen(false);alert("Error while contacting server. "+res);
            EventBus.dispatch("logout");
            navigate("/admin/Login");

          }
   }

  };


  const handleCancel = (value: string) => {
  setOpen(false);
  setDelOpen(false);
  };


  const openPhoto = async (path,id,scrollY) => {
  //let photourl;
  setIsLoading(true);
  let myHeaders = authHeader();
  const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/file?fileName="+path;
  //console.log("***",id,`${process.env.PUBLIC_URL}/pdf.worker.min.js`);
  setScrollY(scrollY);
  setRegtoEdit(pendingPayments.filter(p=> p.registrationId===id).map((filtered,i) =>  {return filtered}));

  //console.log(pendingPayments.filter(p=> p.installmentId===id).map((filtered,i) =>  {return filtered}));

  const d= await axios.get(API_URL
      ,{responseType: "blob",  headers:myHeaders})
      .then(response => {
        //console.log(response.data);
        setSelectedFile(URL.createObjectURL(response.data));
      }).catch(err=>{
        EventBus.dispatch("logout");
        //alert("Cannot contact server." +err);
          navigate("/admin/Login");
          setIsLoading(false);
            //setOpen(false);
        //  setCurrentUser(undefined);
      }).then(r =>{        //setOpen(true);
              //console.log(open);console.log(selectedFile);
              setIsLoading(false);
            //  setOpen(true);

            })
  };




  const MatEdit = ({ index }) => {
    //const apiRef = useGridApiContext();

      const handleEditClick = () => {
      //  console.log(pendingPayments.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered}));
        setRegtoEdit(pendingPayments.filter(p=> p.registrationId===index).map((filtered,i) =>  {return filtered}));
        //setEditid(index);
        //Only not Cancelled and not Card paid
        if(!pendingPayments.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.cancelled})[0] &&
        !pendingPayments.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.cardPayment})[0])
          setOpen(true);
        else {setAlertOpen(true);      setAlertSeverity("warning");setAlertMessage("Nothing to edit!");}
      }
      const handleDeleteClick = () => {
          //console.log(pendingPayments.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.cancelled})[0]);
          setRegtoEdit(pendingPayments.filter(p=> p.registrationId===index).map((filtered,i) =>  {return filtered}));
          //setEditid(index);
          //Only not Cancelled
          if(!pendingPayments.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.cancelled})[0])
            setDelOpen(true);
            else {setAlertOpen(true);      setAlertSeverity("warning");setAlertMessage("Nothing to delete!");}

      }


      return <FormControlLabel
           control={
             <Box>
               <IconButton color="secondary" aria-label="add an alarm" onClick={handleEditClick} >
                   <EditIcon style={{ color: blue[500] }} />
               </IconButton>
               <IconButton color="secondary" aria-label="add an alarm" onClick={handleDeleteClick} >
                   <DeleteIcon style={{ color: red[500] }} />
               </IconButton>
               </Box>
           }
             />
    };


  const columns: GridColDef[] = [
    {
       field: "actions",
       headerName: "Actions",
       sortable: false,
       width:100,
       disableClickEventBubbling: true,
       renderCell: (params) => {
           return (
               <div className="actions" style={{ cursor: "pointer" }}>
                   <MatEdit index={params.row.registrationId} />
                 {/* <MatSave index={params.row.registrationId} />*/}
                </div>
           );
        }

     },
  { field: 'registrationId', headerName: 'ID', width: 50, type:'number',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'registrationDate',flex: 0.3, headerName: 'Registration Date', minWidth: 100,type:'string',
      valueGetter: (params) => (params.row.registrationDate!==""?`${moment(params.row.registrationDate).format('YYYY-MM-DD')}`:"")

      },
  { field: 'name',flex: 0.5, headerName: 'Name' , minWidth: 100,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'lastName',flex: 0.5, headerName: 'Last Name', minWidth: 100,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
    /*    { field: 'dateOfBirth',flex: 0.3, headerName: 'Date of Birth', minWidth: 100,type:'string',
          valueGetter: (params) => ((params.row.dateOfBirth!=="" || params.row.dateOfBirth!=null) ?`${moment(params.row.dateOfBirth).format('YYYY-MM-DD')}`:"")
        },*/
          {
            field: 'dateOfBirth',
            flex: 0.3,
            headerName: 'Date of Birth',
            minWidth: 100,
            type: 'string',
            valueGetter: (params) => {
              if (params.row.dateOfBirth == null) {
                return "";
              }
              return moment(params.row.dateOfBirth).format('YYYY-MM-DD');
            }
          },

  { field: 'email',flex: 0.5, headerName: 'Email', minWidth: 200,type:'string',sortable:false,  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'country',flex: 0.3, headerName: 'Country', minWidth: 100,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
      /*   { field: 'code', headerName: 'Code', width: 100, type:'number',  renderCell: (params: any) =>  (
             <Tooltip title={params.value} >
              <span className="table-cell-trucate">{params.value}</span>
              </Tooltip>)},
               { field: 'uid',flex: 0.5, headerName: 'UID' , minWidth: 150,type:'string',  renderCell: (params: any) =>  (
             <Tooltip title={params.value} >
              <span className="table-cell-trucate">{params.value}</span>
              </Tooltip>)},
  { field: 'organisationName',flex: 0.4, headerName: 'Organisation Name', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},

      {
       field: 'diplomaPhotoFilePath',
       flex: 0.5,
       headerName: 'Competitor Photo',
       minWidth: 100,
       type: 'string',
       renderCell: (params: any) => (
       <Tooltip title={params.value}>
         {params.value ? (
           <Button
             variant="contained"
             color="primary"
             onClick={async() =>{
               //console.log(selectedFile,params.value,params.id);
               await openPhoto(params.value,params.id,scrollY);
               setOpenPhoto(true);
              }}
           >
             Open File
           </Button>
         ) : (
           <span className="table-cell-truncate">{params.value}</span>
         )}
       </Tooltip>
       ),
     },*/
  { field: 'pendingPayment',flex: 0.3, headerName: 'Pending', minWidth: 70,type:'boolean'},
  { field: 'discount',flex: 0.3, headerName: 'Discount', minWidth: 70,type:'boolean'},
  { field: 'paid',flex: 0.3, headerName: 'Is Paid', minWidth: 70,type:'boolean'},
  { field: 'cardPayment',flex: 0.3, headerName: 'Card Payment', minWidth: 70,type:'boolean'},
  { field: 'cancelled',flex: 0.3, headerName: 'Cancelled', minWidth: 80,type:'boolean'},
  { field: 'refundStatus',flex: 0.3, headerName: 'Refund Status', minWidth: 70,type:'boolean'},
  { field: 'refundDate',flex: 0.3, headerName: 'Refund Date', minWidth: 100,type:'string',
valueGetter: (params) => (params.row.refundDate!==""?`${moment(params.row.refundDate).format('YYYY-MM-DD')}`:"")

},
{ field: 'lastFourDigits', headerName: 'Last Four Digits', minWidth: 100, type:'number',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},

//registrationDate
//moment(formValues.dateofbirth).format('YYYY-MM-DD')

  { field: 'totalFee',flex: 0.3, headerName: 'Total Fee', minWidth: 100,type:'number',
valueFormatter: (params) => {
              if (params.value == null) {
                return '';
              }

              const valueFormatted = Number(params.value);
              return `${valueFormatted} €`;
            }
          },
          { field: 'phone',flex: 0.2, headerName: 'Phone', minWidth: 150,type:'string',sortable:false,  renderCell: (params: any) =>  (
               <Tooltip title={params.value} >
                <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>)},
    { field: 'generalCategory',flex: 0.4, headerName: 'General Category', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
         <Tooltip title={params.value} >
          <span className="table-cell-trucate">{params.value}</span>
          </Tooltip>)},
  { field: 'tests',flex: 2, headerName: 'Tests', minWidth: 200,type:'string',
  valueGetter: (params) => `${params.row.tests.toString()}`,
  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)
 }

];

const columns2: GridColDef[] = [
{ field: 'mirrorNumber', headerName: 'Mirror Number', width: 70, type:'number',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
{ field: 'name',flex: 0.5, headerName: 'Name' , minWidth: 100,type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
{ field: 'lastName',flex: 0.5, headerName: 'Last Name', minWidth: 100,type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
{ field: 'country',flex: 0.5,  headerName: 'Country', minWidth: 100, type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
  { field: 'generalCategory',flex: 0.4, headerName: 'General Category', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
  { field: 'test',flex: 0.4, headerName: 'Test', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},

];

const columns3: GridColDef[] = [
{ field: 'registrationId', headerName: 'ID', width: 70, type:'number',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
  { field: 'registrationDate',flex: 0.3, headerName: 'Registration Date', minWidth: 100,type:'string',
      valueGetter: (params) => (params.row.registrationDate!==""?`${moment(params.row.registrationDate).format('YYYY-MM-DD')}`:"")

      },
{ field: 'name',flex: 0.5, headerName: 'Name' , minWidth: 100,type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
{ field: 'lastName',flex: 0.5, headerName: 'Last Name', minWidth: 100,type:'string',  renderCell: (params: any) =>  (
     <Tooltip title={params.value} >
      <span className="table-cell-trucate">{params.value}</span>
      </Tooltip>)},
      { field: 'code', headerName: 'Code', width: 100, type:'number',  renderCell: (params: any) =>  (
           <Tooltip title={params.value} >
            <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>)},
      { field: 'email',flex: 0.5, headerName: 'Email', minWidth: 200,type:'string',sortable:false,  renderCell: (params: any) =>  (
           <Tooltip title={params.value} >
            <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>)},
      { field: 'country',flex: 0.3, headerName: 'Country', minWidth: 100,type:'string',  renderCell: (params: any) =>  (
           <Tooltip title={params.value} >
            <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>)},
      { field: 'uid',flex: 0.5, headerName: 'UID' , minWidth: 150,type:'string',  renderCell: (params: any) =>  (
           <Tooltip title={params.value} >
            <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>)},
      { field: 'organisationName',flex: 0.4, headerName: 'Organisation Name', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
           <Tooltip title={params.value} >
            <span className="table-cell-trucate">{params.value}</span>
            </Tooltip>)},
            { field: 'organisationPresident',flex: 0.4, headerName: 'Organisation President', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
                 <Tooltip title={params.value} >
                  <span className="table-cell-trucate">{params.value}</span>
                  </Tooltip>)},
        { field: 'dateOfBirth',flex: 0.3, headerName: 'Date of Birth', minWidth: 100,type:'string',
          valueGetter: (params) => (params.row.dateOfBirth!==""?`${moment(params.row.dateOfBirth).format('YYYY-MM-DD')}`:"")
          },
  { field: 'generalCategory',flex: 0.4, headerName: 'General Category', minWidth: 150,type:'string',  renderCell: (params: any) =>  (
       <Tooltip title={params.value} >
        <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>)},
        { field: 'tests',flex: 2, headerName: 'Tests', minWidth: 200,type:'string',
        valueGetter: (params) => `${params.row.tests.toString()}`,
        renderCell: (params: any) =>  (
             <Tooltip title={params.value} >
              <span className="table-cell-trucate">{params.value}</span>
              </Tooltip>)
       },

  { field: 'phone',flex: 0.2, headerName: 'Phone', minWidth: 150,type:'string',sortable:false,  renderCell: (params: any) =>  (
             <Tooltip title={params.value} >
              <span className="table-cell-trucate">{params.value}</span>
              </Tooltip>)},

    { field: 'totalFee',flex: 0.3, headerName: 'Total Fee', minWidth: 100,type:'number',
  valueFormatter: (params) => {
                if (params.value == null) {
                  return '';
                }

                const valueFormatted = Number(params.value);
                return `${valueFormatted} €`;
              }
            },

];


  const rows: GridRowsProp = pendingPayments;
  const rows2: GridRowsProp = confidentialList;
  const rows3: GridRowsProp = failedRegistrations;

  const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    alert("Authentication error. " + err);
    return null;
  }
};

const fetchData = async () => {
 setIsLoading(true);

 try {
   if (AuthService.getCurrentUser()) {
     setCurrentUser(AuthService.getCurrentUser());
     if (AuthService.getCurrentUser()) {
       const decodedJwt = parseJwt(AuthService.getCurrentUser().accessToken);

       if (decodedJwt.exp * 1000 < Date.now()) {
         EventBus.dispatch("logout");
         navigate("/admin/Login");
       }
     }
   } else {
     EventBus.dispatch("logout");
     navigate("/admin/Login");
   }

   const myHeaders = authHeader();
   const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/registrations";
   const response = await axios.get(API_URL1,{ headers:myHeaders});
   const data = response.data;
   console.log(response.data);
  setAlertOpen(true);
  setAlertSeverity("success");
  setAlertMessage("Data refreshed!");
  setPendingPayments(data);

  const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/confidentialLists";
  const response2 = await axios.get(API_URL2,{ headers:myHeaders});
  const data2 = response2.data;
//  console.log(response2.data);
  setConfidentialList(data2);

 } catch (err) {
   EventBus.dispatch("logout");
   setAlertOpen(true);
   setAlertSeverity("error");
   setAlertMessage("Cannot contact server." + err);
   navigate("/admin/Login");
 } finally {
   setIsLoading(false);
 }
};


  useEffect(() => {
    //console.log(checkedState);

    let data;

     //const user = AuthService.getCurrentUser();
     const myHeaders = authHeader();

     setIsLoading(true);


     if (AuthService.getCurrentUser())
     {
       //console.log( AuthService.getCurrentUser());
       setCurrentUser(AuthService.getCurrentUser());
       if (AuthService.getCurrentUser()) {
         const decodedJwt = parseJwt(AuthService.getCurrentUser().accessToken);

         if (decodedJwt.exp * 1000 < Date.now()) {
           EventBus.dispatch("logout");
           navigate("/admin/Login");
         }
       }
     }
     else {
       EventBus.dispatch("logout");
       navigate("/admin/Login");
       //setCurrentUser("");
       //window.reload();
     };

  //  if (currentPage =="Registrations")
  //  {
    const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/registrations";
    const response = axios.get(API_URL1,{ headers:myHeaders}).then( response=>{
      //console.log(response);
      return response.data;}).then(data=>
        {
    //    console.log(data);
        setAlertOpen(true);
         setAlertSeverity("success");
        setAlertMessage("Data refreshed!");
        setPendingPayments(data);
        setIsLoading(false);

      //  console.log("LOAD false");
    }).catch(err=>{
      EventBus.dispatch("logout");
      //alert("Cannot contact server." +err);
        navigate("/admin/Login");
        setIsLoading(false);
    })

//}
//  else  if (currentPage =="Confidential List")
//  {

    const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/confidentialLists";
    const response2 = axios.get(API_URL2,{ headers:myHeaders}).then( response=>{
      //console.log(response);
      return response.data;}).then(data=>
        {
        //console.log(data);
        //console.log(confidentialList);

        setConfidentialList(data);
        setIsLoading(false);
      //  console.log(confidentialList);

      //  console.log("LOAD false");
    }).catch(err=>{
      EventBus.dispatch("logout");
      //alert("Cannot contact server." +err);
        navigate("/admin/Login");
        setIsLoading(false);
    })


    const API_URL3= process.env.REACT_APP_BACKEND_URL+"/services/failedRegistrations";
    const response3 = axios.get(API_URL3,{ headers:myHeaders}).then( response=>{
      //console.log(response);
      return response.data;}).then(data=>
        {
        //console.log(data);
        //console.log(confidentialList);

        setFailedRegistrations(data);
        setIsLoading(false);
      //  console.log(confidentialList);

      //  console.log("LOAD false");
    }).catch(err=>{
      EventBus.dispatch("logout");
      //alert("Cannot contact server." +err);
        navigate("/admin/Login");
        setIsLoading(false);
    })
  //}

  // const timer = setTimeout(() => {
  //   setAlertOpen(false);
  // }, 3000); // Auto-hide after 3 seconds
  //
  // return () => {
  //   clearTimeout(timer);
  // };

},[]);


  return (


<div >
<div className="pendingpayments">
    <Dialog
      open={(open||delOpen)}
      //onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {open&&<DialogTitle id="alert-dialog-title">
        Change status payment to: {regtoEdit&&regtoEdit[0].pendingPayment?'Paid':'Pending'}
      </DialogTitle>}
      {delOpen&&<DialogTitle id="alert-dialog-title">
      {regtoEdit[0].cardPayment?"Deleting registration will initiate the automatic stripe refund process.Are you sure you want to proceed?":
      "Delete registration?"}
        </DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Registration Id: {regtoEdit&&regtoEdit[0].registrationId}<br/>
        Name: {regtoEdit&&regtoEdit[0].name} {regtoEdit&&regtoEdit[0].lastName}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleCancel}>No</Button>
        {open&&<Button disabled={isLoading} onClick={handleSave} autoFocus>Yes</Button>}
        {delOpen&&<Button disabled={isLoading} onClick={handleSave} autoFocus>{regtoEdit[0].cardPayment?"Proceed to Refund":"Delete Registration"}</Button>}
      </DialogActions>
    </Dialog>

    {selectedFile && (
  <Dialog open={openphoto} onClose={() => setOpen(false)}  PaperProps={{ style: { maxHeight: '90vh', width: '90vw' } }}>
  <DialogTitle>
  <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
  Competitor Photo/Registration ID: {regtoEdit[0].registrationId}
  </Typography>
  <Tooltip title="Close">
  <IconButton edge="end" color="inherit" onClick={() => setOpenPhoto(false)} aria-label="close">
   <CloseIcon />
  </IconButton>
  </Tooltip>
  <Tooltip title="Download">
  <IconButton
   color="primary"
   onClick={(e) => { //Download File
     e.stopPropagation();
     const link = document.createElement('a');
     link.href = selectedFile;
     //console.log(selectedFile);
     let filename = regtoEdit[0].diplomaPhotoFilePath;
     filename = filename.replace(/\//g, '_');
     link.download = filename;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
   }}
  >
   <GetAppIcon />
  </IconButton>
  </Tooltip>


  </DialogTitle>
  <DialogContent>
  {
   <img
    key={'p'}
    id="photo"
    src={selectedFile}
    alt="Selected File"
    style={{maxWidth: '100%', height: 'auto'}}
   />

}

  </DialogContent>
  </Dialog>
  )}





    {isLoading &&
    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="secondary" />
    <span style={{ justifyContent: "center", position: "fixed"}}>Loading...please wait</span>
    </div>
    }
    </div>
<div>
{currentUser && (

  <AppBar theme={theme}
 position="static">
        <Toolbar>
          <IconButton
            size="small"
            variant="dense"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}

          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
{currentPage}
          </Typography>
      {false&&    <Button  disabled={isLoading||currentPage!=='Registrations'} onClick={handleExport}>
          <FileDownloadIcon/>
          Excel
          </Button>}

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
           {pages.map((page) => (
             <Button
               key={page}
               disabled={isLoading}
               onClick={() => {setCurrentPage(page)}}
               sx={{ my: 2, color: 'white', display: 'block' }}
               style={{ boxShadow: page===currentPage ? '0px 3px 5px 2px rgba(0,0,0,0.3)' : '' }}
             >
               {page}
             </Button>
           ))}

         </Box>
         <Button color="primary" startIcon={<RefreshIcon />} onClick={fetchData}
disabled={isLoading} color="inherit" style={{ boxShadow: '0px 3px 5px 2px rgba(0,0,0,0.3)' }}
         >
         Refresh Data
         </Button>
         <Button disabled={isLoading} color="inherit" style={{ boxShadow: '0px 3px 5px 2px rgba(0,0,0,0.3)' }}>      <Link style={{ textDecoration: 'none', color: 'black'  }} to={"/admin/Login"}>
                  {"Logged in as "+currentUser.username}
                </Link></Button>
        </Toolbar>
      </AppBar>

)}
<Box sx={{ width: '100%' }}>
     <Fade in={alertopen} timeout={{ enter: 1000, exit: 4000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
       addEndListener={() => {
         setTimeout(() => {
           setAlertOpen(false)
         }, 2000);
       }}
>
       <Alert severity={alertSeverity}
         action={
           <IconButton
             aria-label="close"
             color="inherit"
             size="small"
             onClick={() => {
               setAlertOpen(false);
             }}
           >
             <CloseIcon fontSize="inherit" />
           </IconButton>
         }
         sx={{ mb: 2 }}
       >
        {alertmessage}
       </Alert>
     </Fade>
   </Box>

           {currentPage==="Registrations"&&<Box style={{ height:  600 , width: '100%' }}
           sx={{

          '& .red': {
            //backgroundColor:  '#DCE1E5','#EF5645'
            backgroundColor:  '#EF5645',
            color: '#1a3e72',
          },
          '& .orange': {
            backgroundColor: '#FFD858',
            color: '#1a3e72',
          },
          '& .green': {
            backgroundColor: '#89E682',
            color: '#1a3e72',
          },
        }}
           >
           {/*color="#e20e14"*/}
           <Typography variant="h5"  gutterBottom component="div">

                </Typography>

           {<StripedDataGrid
           rowsPerPageOptions={[ 10, 25,100]}
           pageSize={pageSize}
           onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
           pagination
           csvOptions={{
                 fileName: currentPage, // Use the dynamic filename here
               }}
          loading={isLoading}  initialState={{sorting:{sortModel:[{field:'registrationId',sort:'asc'}]}}}
                disableExtendRowFullWidth editMode="row" sortingOrder={['asc','desc']}
                disableSelectionOnClick
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
           getRowId={rows => rows.registrationId}
           getRowClassName={(params) =>
  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
}

           getCellClassName={(params: GridCellParams<number>) => {
             if (params.field === 'pendingPayment' && params.value === true) {
               return params.value ===true ? 'orange' : 'cold';
             }
             if (params.field === 'paid' && params.value === true) {
               return params.value ===true ? 'green' : 'orange';
             }
             if (params.field === 'cancelled' && params.value === true) {
               return params.value ===true ? 'red' : 'cold';
             }
            //  else return 'cold'
              }}

           rows={rows} columns={columns}
            components={{ Toolbar: GridToolbar }} experimentalFeatures={{ newEditingApi: true }}
            sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            textOverflow: "clip",
                            whiteSpace: "break-spaces",
                            lineHeight: 1
                        }
                    }}

            />}
     </Box>}

     {currentPage==="Confidential List"&&<Box style={{ height:  600 , width: '100%' }}
     sx={{
    '& .red': {
      //backgroundColor:  '#DCE1E5','#EF5645'
      backgroundColor:  '#EF5645',
      color: '#1a3e72',
    },
    '& .orange': {
      backgroundColor: '#FFD858',
      color: '#1a3e72',
    },
    '& .green': {
      backgroundColor: '#89E682',
      color: '#1a3e72',
    },
  }}
     >
     <Typography variant="h5"  gutterBottom component="div"></Typography>
     {<StripedDataGrid
     rowsPerPageOptions={[ 10, 25,100]}
     pageSize={pageSize}
     pagination
    loading={isLoading}
    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableExtendRowFullWidth disableSelectionOnClick

          editMode="row" sortingOrder={['asc','desc']}
     getRowClassName={(params) =>params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
     rows={rows2} columns={columns2}
      components={{ Toolbar: GridToolbar }}
      sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                      textOverflow: "clip",
                      whiteSpace: "break-spaces",
                      lineHeight: 1
                  }
              }}
                getRowId={rows2 => rows2.index} //getRowId={()=>Math.floor(Math.random() * 10000)}
      />}
</Box>}

{currentPage==="Failed Registrations"&&<Box style={{ height:  600 , width: '100%' }}
sx={{
'& .red': {
 //backgroundColor:  '#DCE1E5','#EF5645'
 backgroundColor:  '#EF5645',
 color: '#1a3e72',
},
'& .orange': {
 backgroundColor: '#FFD858',
 color: '#1a3e72',
},
'& .green': {
 backgroundColor: '#89E682',
 color: '#1a3e72',
},
}}
>
<Typography variant="h5"  gutterBottom component="div"></Typography>
{<StripedDataGrid
rowsPerPageOptions={[ 10, 25,100]}
pageSize={pageSize}
pagination
loading={isLoading}
slotProps={{  csvOptions: { fileName: 'mpla' }  }}
onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
     disableExtendRowFullWidth disableSelectionOnClick

     editMode="row" sortingOrder={['asc','desc']}
getRowClassName={(params) =>params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
rows={rows3} columns={columns3}
 components={{ Toolbar: GridToolbar }}
 sx={{
             '& .MuiDataGrid-columnHeaderTitle': {
                 textOverflow: "clip",
                 whiteSpace: "break-spaces",
                 lineHeight: 1
             }
         }}
           getRowId={rows3 => rows3.registrationId} //getRowId={()=>Math.floor(Math.random() * 10000)}
 />}
</Box>}



{isLoading &&
<div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
              <CircularProgress color="secondary" />
<span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>
</div>
}


</div>
</div>

  );

}

export default PendingPayments;
