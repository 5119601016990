import {useState,useEffect,useRef} from "react";
import React from "react";
import axios from "axios";
import Checkbox from '@material-ui/core/Checkbox';
import { CircularProgress, Button } from '@material-ui/core';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
//import {Link,useHistory } from 'react-router-dom';

//import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
/**/
import CreditCardIcon from '@mui/icons-material/CreditCard';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useParams} from "react-router-dom";
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
//import Select from '@material-ui/core/Select';
import {  InputLabel, Select, MenuItem } from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


//import InputLabel from '@mui/material/InputLabel';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/';
import {  LocalizationProvider } from '@mui/x-date-pickers';
//import bgLocale from 'date-fns/locale/bg';
//import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Moment from 'react-moment';
//import 'moment-timezone';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
//import CardContent from '@mui/material/CardContent';
//import CardActions from '@mui/material/CardActions';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import MoreVertIcon from '@mui/icons-material/MoreVert';


import {useNavigate} from 'react-router-dom';
//import MuiPhoneNumber from 'mui-phone-number';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    background: "rgb(255, 192, 203)",
    maxLength:"4",
    size:"6"
  }
}));


const CreateRegistration=() => {
  //const {section} = useParams();
  //const section = "Juror/Trainer";
  //console.log(section);
  const initialValues = {president:"",orgname:"",country:"",
      code:"",fname:"",lname:"",dateofbirth:null,email:"",phone: ""
      ,section:"Juror/Trainer" ,idfile:"",diplomafile:"",payLaterCode:""
    };

    // const initialValues = {president:"",orgname:"",country:"Albania",
    //     code:"",fname:"",lname:"",email:"",phone: ""
    //     ,section:section ,idfile:"",diplomafile:""
    //   };
  //Selected Tests. Used for Pricing
  const [selectedTests,setSelectedTests]=useState([]);
  //FormValues
  const [formValues,setFormValues] = useState(initialValues);
  const [formErrors,setFormErrors] = useState([]);

  //Boolean indicators of From State
  const [isSubmit,setIsSubmit] = useState(false);
//Pay
  const [isPay,setIsPay] = useState(false);

  const [isCountryLoading,setIsCountryLoading] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  //const [validated,setValidated] = useState(false);

  const [loadingmessage,setLoadingmessage]=useState("Loading...Please wait");

  const [registrationId,setregid]= useState(null);
  const [successUpload,setSuccessUpload] = useState(false);

  const [photofiles, setPhotoFiles] = useState([]);

  //Accepted file types for online submition
  const types = ['image/png', 'image/jpeg','image/jpg','image/gif','image/tiff'];
  const maxfilesize="10000000";
  const [countries, setCountries] = useState({});
  //const [value, setValue] = React.useState(new Date());

  const [newCategories,setNewcategories]=useState([]);

  const [isChecked, setIsChecked] = useState(false);
  const [payLater, setpayLater] = useState(false);

  //const [codeAccepted,setCodeAccepted] = useState(false);
  const [codeValid,setCodeValid] = useState(false);
  const [selcountrycodes,setSelcountrycodes]=useState([]);

  const navigate=useNavigate();
  const classes = useStyles();

  const [showBeforeUnloadMessage, setShowBeforeUnloadMessage] = useState(true);

  const [openConfirm, setOpenConfirm] = useState(false);

  const alertRef = useRef();

  //const [selectedValue, setSelectedValue] = React.useState(codeAccepted);
  const handleCloseConfirm = () => {
    setOpenConfirm(false); // Close the dialog without confirming
    setIsSubmit(false);
    setShowBeforeUnloadMessage(true);

  };

  const checkHandler = () => {
    setIsChecked(!isChecked)

  }
    const checkpayLater = () => {
      setpayLater(!payLater)
    }

const payLaterChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});

}

//TODO: Array length should be = total number of tests. Otherwise, 2 dim, Category-Test// BEST keep testid,checked on list, push/empty/getall
  const [checkedState, setCheckedState] = useState(
    new Array(100).fill(false)
);

const [totalPrice, setTotalPrice] = useState(0);
/*Dialog for Code*/
  const [open, setOpen] = React.useState(false);
  //const [selectedValue, setSelectedValue] = React.useState(codeAccepted);


  const handleClickOpen = async () => {
    setIsLoading(true);


    const ORG_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/organisation";

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'plain/text');

    const params={code:formValues.code};
    const queryString = new URLSearchParams(params).toString();
    //console.log(queryString);

    let res = await fetch(ORG_API_URL+"?"+queryString).then(async response=>{
    if(!response.ok){//Error on server
          setFormValues({ ...formValues,code:"",president:"",orgname:""});
          alert('Error contacting server.Check your connection and try again later. Error fetching code: '+response);
          //console.log(res);
          setIsLoading(false);
    }
    else { //fetch ok
      //console.log(response);
      let res1 = await response.json()
      .then(data=> {setFormValues({ ...formValues,president:data.president,orgname:data.name,country:data.country});
                          setIsLoading(false);setOpen(true);
                        //  setSelcountrycodes(countries.filter(country=> country.name===data.country).map(filt=>{return filt.iso2Code.toLowerCase()})[0]);
                        })
      //Code does not exist, fetch returns null
      .catch(err=>{//Code is Not Valid
        setFormValues({ ...formValues,code:"",president:"",orgname:""});
        //alert('Error contacting server.Check your connection and try again later. Error fetching code: '+err);
      setIsLoading(false);setOpen(true);});
    }
    })
    .catch (err=>{alert('Error contacting server.Check your connection and try again later.'+err);setIsLoading(false);})

    //process.env.REACT_APP_BACKEND_URL+"/services/registration?regid="+registrationId
  };



  useEffect(async () => {
    //console.log(newCategories.length);
    let data;
    setIsCountryLoading(true);

    const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/countries";
    const response = await axios.get(API_URL1, data).then( response=>{
      //console.log(response);
      return response.data;}).then(data=>
        {
        setCountries(data);
    }).catch(err=>{
      //console.log(err);
      alert("Error contacting server.Check your connection and try again later. " + err );
      setIsCountryLoading(false);
      //console.log(err);
    })

    const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/generalCategories";
    const responsecat = await axios.get(API_URL2, data).then( responsecat=>{
      return responsecat.data;}).then(datacat=>
        {//Use Params SEction is ok
    //      console.log(datacat);
          setNewcategories(datacat);

    }).catch(err=>{
        alert("Error contacting server.Check your connection and try again later. " + err);

      }).then(e=>
          {setIsCountryLoading(false);}
      )

      //console.log(isLoading+" "+isCountryLoading);
   },[formErrors]);


   useEffect(() => {
     const handleBeforeUnload = (event) => {
       event.preventDefault();
       if (showBeforeUnloadMessage) {
         event.returnValue = "Are you sure you want to leave?";
         return "Are you sure you want to leave?";
       }
     };
     if (showBeforeUnloadMessage) {
   window.addEventListener('beforeunload', handleBeforeUnload);
   }

     return () => {
       window.removeEventListener('beforeunload', handleBeforeUnload);
     };
   }, [showBeforeUnloadMessage]);


const uploadFile3 = async (filetoup,filename,dirname) => {

    let res;
    setLoadingmessage("Uploading file: "+filename+ " for test "+dirname);
    const data = new FormData();
    data.append("file", filetoup);
    data.append("filename",filename);
    data.append("dirname",dirname);

    //console.log(formValues.idfile);
    const F_URL = process.env.REACT_APP_BACKEND_URL+"/services/files";
    //console.log(F_URL);
    try{
      res = await fetch(
      F_URL,
        {
          method: 'put',
          body: data,
          // headers: {}
        })
      //console.log(res);
    } catch (err) {
        //console.log(error);
        alert("Error while uploading files. Check your connection and submit your registration again. " + err );
        return false;
      }
      return res.ok;
  }

//Insert Code input
const codeChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});
}
//Function to "hear" Enter key button on code insert
const handleKeypress = (e) =>{
//console.log(e.key);
   if (e.key === "Enter" ) {
     document.getElementById("codebutton").click();
   }
 }

//Form Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(e.target);
    //console.log("Changed"+e.target.name+":"+e.target.value);

  //  if(e.target.name==='lname' ||e.target.name==='fname' ||e.target.name==='email'){
    //Trim leading & trailing white spaces

    //When Change Section make all checked tests false
    if(e.target.name==='section'){
        //console.log(e.target.value);
        checkedState.fill(false);
        //Empty photofiles. Only one category is alllowed
        setPhotoFiles([]);

         /*if (formValues.country==='Ukraine') {
           setTotalPrice(0);
         }
         else
         {*/
         //***Custom Configuration: When specific section is seleceted, test is selected as well
         // if (e.target.value ==='Guest')
         // {
         //   //Karfwti timi gia to test me TESTID = 4 pou antistoixei sto Section= Guest
         //   setTotalPrice(350);
         //   checkedState.fill(false).fill(true,4,5);
         //   let tests=[];
         //   tests.push(4);
         //   setSelectedTests(tests);
         // }
         // //***Set price to 0 when section is changed
         //   else
           setTotalPrice(0);
    }
    if (e.target.name ==='idfile'|| e.target.name ==='diplomafile')
     {//TODO: SETFORMERRORS
       //console.log(e.target.files[0]);
      // if (e.target.files[0]){
            //console.log(e.target);
            let selected=e.target.files[0];

           //Everything is OK
             if (selected&&types.includes(selected.type) && selected.size<maxfilesize)
             {
               setFormValues({ ...formValues,[name]: selected});

             }//File too large
            else if (selected && selected.size>maxfilesize) {
               e.target.value='';
               setFormValues({ ...formValues,[name]: ''});
               //setFile('');
               alert('File too large, please upload file less than '+maxfilesize/1000000+'MB');
               //return {<Alert severity="error">Accepted file types: {types} Max file size: 5MB </Alert>}
             }//Wrong file type
            else if (selected&&!types.includes(selected.type))
            {
              e.target.value='';
              setFormValues({ ...formValues,[name]: ''});
              alert('Accepted file types:'+ types);
            }
    //  }

       }
      else {
        //console.log("Changed"+e.target.name+":"+e.target.value);
        //console.log("Trimmed"+e.target.name+":"+e.target.value.trim());
        //if(e.target.name==='lname' ||e.target.name==='fname' ||e.target.name==='email')
        //setFormValues({ ...formValues,[name]: value.trim()});
        //else
        setFormValues({ ...formValues,[name]: value});
       }

    };

    //FileToUpload Changes: Only for online sections
    const handleChangeFile=(id,section,number_photos)=>(e) =>{

        let selected = e.target.files[0];
        let filename=e.target.name;


        //Everything is OK
          if (selected&&types.includes(selected.type) && selected.size<maxfilesize)
          {

            if (e.target.name==='frontfile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                   ph.id === id
                       ? { ...ph, frontfile: e.target.files[0] }
                       : { ...ph }
               )
             );

            else if (e.target.name==='sidefile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                   ph.id === id
                       ? { ...ph, sidefile: e.target.files[0] }
                       : { ...ph }
               )
             );
             else if (e.target.name==='sidefile3')
             setPhotoFiles(
               photofiles.map((ph) =>
                    // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                    ph.id === id
                        ? { ...ph, sidefile3: e.target.files[0] }
                        : { ...ph }
                )
              );
              else if (e.target.name==='sidefile4')
              setPhotoFiles(
                photofiles.map((ph) =>
                     // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                     ph.id === id
                         ? { ...ph, sidefile4: e.target.files[0] }
                         : { ...ph }
                 )
               );


          }//File too large
         else if ( selected&& selected.size>maxfilesize) {
            e.target.value='';
            if (e.target.name==='frontfile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   ph.id === id
                       ? { ...ph, frontfile: '' }
                       : { ...ph }
               )
             );

            else if (e.target.name==='sidefile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   ph.id === id
                       ? { ...ph, sidefile: '' }
                       : { ...ph }
               )
             );
             else if (e.target.name==='sidefile3')
             setPhotoFiles(
               photofiles.map((ph) =>
                    ph.id === id
                        ? { ...ph, sidefile3: '' }
                        : { ...ph }
                )
              );
              else if (e.target.name==='sidefile4')
              setPhotoFiles(
                photofiles.map((ph) =>
                     ph.id === id
                         ? { ...ph, sidefile4: '' }
                         : { ...ph }
                 )
               );


            alert('File too large, please upload file less than '+maxfilesize/1000000+'MB');
          }//Wrong file type
           else if (selected&&!types.includes(selected.type)){
           e.target.value='';
           if (e.target.name==='frontfile')
           setPhotoFiles(
             photofiles.map((ph) =>
                  // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                  ph.id === id
                      ? { ...ph, frontfile: '' }
                      : { ...ph }
              )
            );

           else if (e.target.name==='sidefile')
           setPhotoFiles(
             photofiles.map((ph) =>
                  // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                  ph.id === id
                      ? { ...ph, sidefile: '' }
                      : { ...ph }
              )
            );
            else if (e.target.name==='sidefile3')
            setPhotoFiles(
              photofiles.map((ph) =>
                   // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                   ph.id === id
                       ? { ...ph, sidefile3: '' }
                       : { ...ph }
               )
             );
             else if (e.target.name==='sidefile4')
             setPhotoFiles(
               photofiles.map((ph) =>
                    // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                    ph.id === id
                        ? { ...ph, sidefile4: '' }
                        : { ...ph }
                )
              );
          // setFormValues({ ...formValues,[name]: ''});
           alert('Accepted file types:'+ types);
         }

        //setPhotoFiles(list1);
    };

  const handleCheckTest=(e,secname,position,mustUpload)=>{


    /*Only one checkBox can be clicked at a time.*/
    const  updatedCheckedState = checkedState.map((item, index) =>
    index === position ? !item : false);

    setCheckedState(updatedCheckedState);

  // console.log(updatedCheckedState);
   //console.log(checkedState);

   let newList=[];
   if (e.target.checked && mustUpload){
     //append to list
  //  console.log("Checked");
    newList = photofiles.concat({'sectionname':secname,'id': position,
    testName:newCategories.filter((section=> (section.name===secname))).map(x=> x.testResponses.map((test,i)=>{ if (test.testId===position)  {return test.testName}}))[0].toString().replace(/,/g,"")
    ,photonumber:mustUpload,'frontfile':'','sidefile':'','sidefile3':'','sidefile4':''});
      setPhotoFiles(newList);
    //console.log(newCategories.filter((section=> (section.name===secname))).map(x=> x.testResponses.map((test,i)=>{ if (test.testId===position)  {return test.testName}}))[0].toString().replaceAll(",",""));


  } else if (mustUpload){
     //remove from list
     const newList = photofiles.filter((item) => item.id !== position);
     //console.log(photofiles.filter((item) => item.id !== position));
    setPhotoFiles(newList);
  }


  //Selected Tests
  let tests=[];
  for (let i = 0; i < updatedCheckedState.length; i++) {
      if (updatedCheckedState[i] === true) {
          tests.push(i);
      }
  }
  setSelectedTests(tests);
  let tr =
  newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
  .reduce((sum,test)=>{ if (tests.includes(test.testId)) {sum=test.registrationFee} return sum},0));

   /*if (formValues.country==='Ukraine') {
     setTotalPrice(0);
   }
   else
   {*/
     setTotalPrice(tr[0]);/*console.log(tr[0]);*/}
   //};
   /*console.log(selectedTests);

   console.log(newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
   .map((test,i)=>{ if (selectedTests.includes(test.testId))  {return test.testName} else return " "})).toString().replaceAll(","," ").replace(/\s+/g, ' '));
*/
   //console.log(selectedTests.map(test=>{if test.testId})

  const validateSubmit = async (e) => {

    e.preventDefault();
    //setIssubmit(true);
    //console.log(formValues);
    setLoadingmessage("Validating Registration...Please Wait...");
    setIsLoading(true);//console.log("LOAD true");

    if(validate(formValues,photofiles)) {
      //console.log(formValues);
      let formatedDateofBirth= moment(formValues.dateofbirth).format('YYYY-MM-DD');
      let validationError=[];
      const COMP_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/competitorValidation";

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'plain/text');

      //console.log(formValues);
      //console.log(formatedDateofBirth);
      const params={name:formValues.fname,lastName:formValues.lname,country:formValues.country,email:formValues.email,dateOfBirth:formatedDateofBirth,testId:selectedTests};
      const queryString = new URLSearchParams(params).toString();
      //console.log(queryString);
      fetch(COMP_API_URL+"?"+queryString)
        .then(response => response.json())
        .then(data =>
          {if(data.validCompetitorRegistration) {
            //setValidated(true);
            //console.log("True");

            setIsLoading(false);
            setOpenConfirm(true);
            setShowBeforeUnloadMessage(false);

          }
            else {
              validationError.push({name:"Competitor Validation Error",value:  data.errorMessage});
              setFormErrors(validationError);
              setIsLoading(false);//console.log("LOAD false");

              setTimeout(() => {
                     window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                   }, 0);

            };
      })
        .catch(err=>{
          setIsLoading(false);alert("Error contacting server. Check your connection and try again later. " + err );})


    }
    else { setIsLoading(false);
    //  console.log("alert sjould focus");

      if (alertRef.current) {
        window.scrollTo({
          bottom: alertRef.current.scrollIntoView,
          behavior: 'smooth'
        });
    }
  }

  }


// Function to add a hidden field to the form
function addHiddenField(formId, fieldName, fieldValue) {
  var form = document.getElementById(formId);

  // Create a new hidden input element
  var hiddenInput = document.createElement("input");

  // Set the attributes of the new input element
  hiddenInput.type = "hidden";
  hiddenInput.name = fieldName;
  hiddenInput.value = fieldValue;

  // Add the new input element to the form
  form.appendChild(hiddenInput);
}


/*with request parameters*/
const handleConfirm = async () => {
  if (!isSubmit) {
    setIsSubmit(true);
    setIsLoading(true);

    try {
      const formElement = document.getElementById('myForm');

      if (!formElement) {
        throw new Error('Form element not found');
      }
      let formatedDateofBirth= moment(formValues.dateofbirth).format('YYYY-MM-DD');

    //  formData.append('phone', formValues.phone);

      //formData.append('pendingPayment', payLater);
      //formData.append('totalPrice', totalPrice);
      //formData.append('testId',selectedTests[0] );

     //formData.append('testId', JSON.stringify(selectedTests[0]));

     addHiddenField("myForm", "phone", formValues.phone);
     addHiddenField("myForm", "pendingPayment", payLater);
     addHiddenField("myForm", "totalPrice", totalPrice);
     addHiddenField("myForm", "dateOfBirth", formatedDateofBirth);
     addHiddenField("myForm", "testId", selectedTests[0]);

    //console.log(document.getElementById('myForm').elements);


  /*  const form = document.getElementById('myForm');
    Array.from(form.elements).forEach(element => {
      console.log(`Name: ${element.name}, Type: ${element.type}, Value: ${element.value || 'N/A'}`);
    });*/

      await   document.getElementById("myForm").submit();

      setOpenConfirm(false); // Close the dialog after successful submission
      setIsLoading(false);

    } catch (error) {
      alert(`An error occurred: ${error.message}`);
      setIsLoading(false);
      setIsSubmit(false);
    }
  } else {
    alert('Duplicate submission detected. Please wait for the previous submission to complete.');
  }
};



  const validate=(values,photos) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    //const phoneregex =^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$;

    const errors=[];
    //console.log(moment().diff(moment(formValues.dateofbirth), "years")>100);

    values.email=values.email.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.lname=values.lname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.fname=values.fname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');

    if (!values.country)
      {errors.push({name:"country",value:"Country is Required!"});}
      if (!values.fname || values.fname.length>100)
        {errors.push({name:"fname",value:"First Name is Required!"});}
      if (!values.lname || values.lname.length>100)
        {errors.push({name:"lname",value:"Last Name is Required!"});}

      if(!values.email){
              errors.push({name:'email',value:"Email is required!"});
              //push({id: 4, name: "Mehul"})
        }
      if (!regex.test(values.email)){
              errors.push({name:'email',value: "Not a valid email format!"});
          }

      if (!values.dateofbirth||values.dateofbirth=== undefined||!moment(formValues.dateofbirth).isValid()||moment().diff(moment(formValues.dateofbirth), "years")>110)
          {errors.push({name:"dateofbirth",value:  "Enter a valid date of birth!"});}


      if (values.phone.length<8 || values.phone.length>20)
        {errors.push({name:"phone",value: "Enter a valid phone!"});}

      if (payLater&&values.payLaterCode!==process.env.REACT_APP_PENDING_PAYMENT_CODE)
        {errors.push({name:"payLaterCode",value:  "Not a valid special code A"});}
            //console.log(process.env.REACT_APP_PENDING_PAYMENT_CODE+" "+values.payLaterCode);

      /*console.log(errors);*/
      setFormErrors(errors);

      if(errors.length===0)    return true;
      else return false;
  }

  return(

      <div className="create">

      <h1>Registration Form - GLOBAL SYMPOSIUM 2025</h1>
      <Alert severity="info" variant="outlined" sx={{ whiteSpace: 'pre-line',typography: 'body1'}}>

        <strong>
          GLOBAL SYMPOSIUM 2025
          {'\n'}
          MASTER CLASS - JURY SEMINAR
          {'\n'}
          March 23rd & 24th 2025 Milano
               {'\n'}
        Participation Fee: 800 EUR           {'\n'}
(includes Sunday Global Cocktail Night, 2 Days of Lunches, and 2 Coffee Breaks).
  </strong>
    </Alert>

        <Alert severity="info" sx={{ whiteSpace: 'pre-line',typography: 'body1'}} variant="outlined"><b>Please enter the following infromation to proceed to payment!</b></Alert>

        <form id="myForm" action = {process.env.REACT_APP_BACKEND_URL+"/services/registration"} method="POST">

        <fieldset disabled={isLoading}>
        <div className="reqfields">
            {countries.length > 0 && (
                <FormControl size="small" fullWidth>
                <label htmlFor="country">Country</label>
                <Select
                disabled={ isLoading}
                style={{ width: '100%', display: 'block' }} // Ensure the Select itself takes full width and is displayed as block
                inputProps={{
                  style: { textTransform: 'uppercase' },
                  name: "country",
                  id: "country",
                  autoComplete: "off", // Explicitly set autocomplete to "off"
                  value: formValues.country,
                }}
                onChange={handleChange}
                sx={{
       ...(formErrors.some(error => error.name === "country") ? {
         '& .MuiOutlinedInput-notchedOutline': {
           borderColor: 'red',
         },
         '& .MuiSvgIcon-root': {
           color: 'red',
         },
       } : {}),
     }}

                >
                {countries.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name.toUpperCase()}
                  </MenuItem>
                ))}
                </Select>
                </FormControl>
        )}

            <label>Attendee First Name</label>
            <TextField type="text" required
            error={formErrors.some(error => error.name === "fname")}
            disabled={isLoading}
            style ={{width: '100%'}}
            name = "fname" value={formValues.fname}
            onChange={handleChange}/>

            <label>Attendee Last Name</label>
            <TextField type="text" required
              error={formErrors.some(error => error.name === "lname")}
            disabled={isLoading}
            style ={{width: '100%'}}
            name = "lname" value={formValues.lname}
            onChange={handleChange}
            />

            <div style={{ textAlign: 'left' }}>
            <label>Attendee Birth Date</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
               <DesktopDatePicker
               referenceDate={new Date('1990-01-01')}
                   disableFuture
                   name="dateofbirth"
                   minDate={new Date("1920-01-01")}
                   views={['year', 'month', 'day']}
                   openTo="year"
                   style={{ flex: 1 }}
                   disabled={ isLoading}
                   value={formValues.dateofbirth}
                   error={formErrors.some(error => error.name === "dateofbirth")}
                   onChange={(newValue) => {
                       setFormValues({ ...formValues, ['dateofbirth']: newValue });
                   }}
                   slotProps={{
                     textField: {
                       inputProps: {
                         readOnly: true,
                       },
                       error: formErrors.some(error => error.name === "dateofbirth"),
                     }
                   }}
               />
            </LocalizationProvider>
            </div>

            <label>Attendee Email</label>
            <TextField type="email" required
            error={formErrors.some(error => error.name === "email")}

            disabled={isLoading}
            style ={{width: '100%'}}
            name= "email" value={formValues.email}
            onChange={handleChange}
            />

            <label>Attendee Phone</label>
            <PhoneInput required name="phone" key="phone"   countryCodeEditable variant="outlined"
            color="secondary"
              disabled={isLoading}
              name="phone"
            value={formValues.phone}
            onChange={(newValue) => {
             setFormValues({ ...formValues,['phone']: newValue});
            }}
            />

              </div>

              {!isCountryLoading&& (newCategories.length>0)&&formValues.section!==""
              &&<Box sx={{
    border: 1,
    borderColor: 'grey.300',
    borderRadius: 1,
    padding: 1,
  }}>

              <FormControl key={"fc_"+formValues.section} sx={{ m: 1 }} variant="standard" fullWidth>
              <FormLabel>Attendance type (check one field only for the admission and diploma)<span className="error"><b>*</b></span></FormLabel>

              <RadioGroup row   key={"tf_"+formValues.section}  size="small" disabled={isLoading||isCountryLoading}
               id="section" name="section"  value={formValues.section}
                    onChange={handleChange}>

              {newCategories.map((item,i) => {
              //console.log(item,formValues.section,newCategories.length);
              return (<FormControlLabel key={"mi_"+i} control={<Radio />}  value={item.name}
              label={item.name /*Take price from first test of section*/
                +" "+item.testResponses[0].registrationFee+" "  + "€"} />); //map(filt=>{return filt.frontfile})[0]
              })}
              </RadioGroup></FormControl>
              </Box>}
            {
              !isCountryLoading&&(newCategories.length>0)&&formValues.section!=="" && newCategories.filter(section=> section.name===formValues.section).map((section,j) => (
              <div key={j} className = "uploadphotos" >
              <Box sx={{
    border: 1,
    borderColor: 'grey.300',
    borderRadius: 1,
    padding: 1,
  }}>
              <FormLabel>Choose section (only one allowed)<span className="error"><b>*</b></span></FormLabel>

                <ul  key={j}  className="testlist">
                  { section.testResponses.map((test,i) => (

                  <li key={i} className="unordered-list">
                    <div  key={i} className="list-item">
                      <label  key={i}   htmlFor={`custom-checkbox-${i}`}>
                        <Checkbox color="default"
                          id={`custom-checkbox-${i}`}
                          label={test.testName}
                          value={test.testId}
                          checked={checkedState[test.testId]}
                          onChange={(event) => handleCheckTest(event,section.name ,test.testId,test.uploadRegistrationPhotos)}
                        />{test.testName/*+" ("+test.registrationFee+"$)"*/}  </label>

                  </div>
                  </li>

                ))
              }
                </ul>
                </Box>
                </div>
              ))

            }

            {false&&<Stack direction="column" className="agree">
            <label>
            <Checkbox color="default"
                      id="agree"
                      checked={isChecked}
                      onChange={checkHandler}/>
            I, the undesigned, have read and approve this discharge and release </label>
            <a href="https://omchairworld.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms&Conditions</a>
            </Stack>}

</fieldset>
          {/*<Alert severity="warning" variant="outlined"><b>Do not enter organisation code here. Ask your president for pending payment code which allows you to pay later.</b></Alert>*/}
          <Stack direction="row" spacing={13} alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center">
          <Checkbox color="primary"
                    id="paylater"
                    checked={payLater}
                    onChange={checkpayLater}
                    disabled = {isLoading ? true : false}
                    />
                    <span>If you have code A press here and enter here:</span>
          </Stack>
          <TextField type ='password' required name="payLaterCode"
        variant="outlined"
           error={formErrors.some(error => error.name === "payLaterCode")}
           value={formValues.payLaterCode}
           disabled = {!payLater||isLoading ? true : false}
            InputProps={{ className: classes.input }}
            sx={{
    width: { sm: 100, md: 100 }
}}
          onChange={payLaterChange}/>
</Stack>
        <section>
        <div className="description">
        <Paper><b>Total Price is {totalPrice}€</b></Paper>
        </div>
        </section>

          {formErrors.length>0&&
            <Box className="errors">
          <Alert variant="outlined" severity="error">
          {formErrors.map((error,i)=>(<p key={i}>{error.value}</p>))}
          </Alert></Box>}

          {successUpload&&!isLoading&&!payLater&&<div className="proceed">
          <Alert variant="outlined" severity="warning" > <b>Your registration will not be completed unless you pay the total fee.
          Proceed to payment to complete your registration!</b></Alert>
          </div>}

            {/*Confirmation Dialog*/}
              <Dialog open={openConfirm} onClose={handleCloseConfirm} closeAfterTransition={false}>
              <DialogTitle><strong>Please review the details below before submitting: </strong></DialogTitle>
              <DialogContent>

             <Box>
             <DialogContentText>

             <Paper>
             <p><b>First Name: </b> {formValues.fname}</p>
               <p><b>Last Name: </b>{formValues.lname}</p>
               <p><b>Date of Birth: </b>{moment(formValues.dateofbirth).format('YYYY-MM-DD')}</p>
               <p><b>Country: </b>{formValues.country}</p>
               <p><b>Email: </b>{formValues.email}</p>
               <p><b>Total Fee: </b>{totalPrice}€</p>
               <p><b>Attendance Type: </b>{formValues.section}</p>

               <div><b>General Categories: </b>{newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
               .map((test,i)=>{ if (selectedTests.includes(test.testId))  {return test.testName} else return " "})).toString().replace(/,/g," ").replace(/\s+/g, ' ')}</div>

             <div>
               {newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
               .map((sum,test)=>{ if (selectedTests.includes(test.testId))  return test.testName}))}
                       </div>
               </Paper>

                    {payLater && (
                 <p style={{ marginTop: '10px', fontStyle: 'italic' }}>
                   *Your payment will be confirmed as FULLY PAID when the OMC Bank accredits your Wire/Transfer.
                 </p>
               )}
                    </DialogContentText >

            </Box>
              </DialogContent>
              <DialogActions>
                 <Button
                   disabled={isSubmit}
                   onClick={handleCloseConfirm}
                   style={{
                     border: '2px solid #000', // Example border color
                     backgroundColor: '#42315E', // Light grey background
                     color: '#fff',
                     '&:hover': { // Styles for hover state
                       /*backgroundColor: '#e0e0e0', */
                       backgroundColor: '#393E70',
                     },
                   }}
                 >
                   Cancel
                 </Button>
                 <Button
                   disabled={isSubmit}
                   onClick={handleConfirm}
                   style={{
                     border: '2px solid #000', // Example border color
                     backgroundColor: '#393E70', // Light grey background
                     color: '#fff',
                     '&:hover': { // Styles for hover state
                       /*backgroundColor: '#e0e0e0', */
                       backgroundColor: '#393E70',
                     },
                   }}
                 >
                   Confirm
                 </Button>
               </DialogActions>
            </Dialog>

          {!isLoading&&<div>
           <Button  ref={alertRef}  endIcon={<UploadIcon/>}
            onClick = {validateSubmit} variant="contained"
            sx={{ backgroundColor: '#FF0000' }} // Change this to your desired color
            disabled={(totalPrice<=0 || isSubmit) }
            type="button" autoFocus >
             {"Submit Registration"}
           </Button>
           </div>}

          {(isCountryLoading||isLoading /*&&!isPay*/) &&
          <div className="circular" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <CircularProgress thickness={1} size={150}/>
          <span style={{ justifyContent: "center", position: "fixed", top: "65%" }}>{loadingmessage}</span>
          </div>
          }
        </form>
      </div>
  );
}

export default CreateRegistration;
