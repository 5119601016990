import React from "react";


const Navbar = () => {

return (
    <nav className="navbar">
      <h1>  </h1>
      <a  href="https://omchairworld.com/" target="_blank" rel="noopener noreferrer">
          <img src={process.env.PUBLIC_URL + "/Symposium Form banner.PNG"}  alt="logo" width= "100%" height="200px" />
          </a>

      </nav>
);

}


export default Navbar;
